import React from "react";
import "./testimonialSection.scss";
import { FaQuoteLeft, FaStar } from "react-icons/fa";

const TestimonialSection = () => {
  const testimonials = [
    {
      id: 1,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, odio id vestibulum tempus.",
      author: "John Doe",
      position: "CEO, Company A",
      stars: 5, // Add star rating for this testimonial
    },
    {
      id: 2,
      text: "Vestibulum in lectus et purus fringilla volutpat. Sed mattis urna non ex gravida, auctor efficitur dolor auctor.",
      author: "Jane Smith",
      position: "Founder, Company B",
      stars: 4, // Add star rating for this testimonial
    },
    {
      id: 3,
      text: "Fusce auctor lectus vel nisl ultrices, id interdum velit consectetur. Nam ut mattis nisi.",
      author: "James Brown",
      position: "CTO, Company C",
      stars: 5, // Add star rating for this testimonial
    },
  ];

  return (
    <div className="testimonial-section">
      <div className="testi-section">
        <div className="border"></div>
        <p>Testimonials</p>
        <h2>What our clients say</h2>
      </div>
      <div className="testimonialContainer">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="photo-testimonial-box">
            <FaQuoteLeft className="quote-icon" />
            <p className="testimonial-text">{testimonial.text}</p>
            <div className="star-rating">
              {Array.from({ length: testimonial.stars }, (_, index) => (
                <FaStar key={index} className="star-icon" />
              ))}
            </div>
            <div className="author-info">
              <p className="author-name">{testimonial.author}</p>
              <p className="author-position">{testimonial.position}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSection;

// ProductsSection.js
import React from "react";
import "./productsSection.scss";
import img5 from "../scss/image/5.jpg";

const ProductsSection = () => {
  return (
    <div className="products-section">
      <div className="productContainer">
        <div className="left-section">
          <div className="image-box">
            <img src={img5} alt="Cycle Accessories" />
          </div>
        </div>
        <div className="right-section">
          <h2>About Us</h2>
          <h3>Quality Cycle Accessories Store</h3>
          <p>
            Welcome to our premium cycle accessories store, where you'll find a
            wide range of high-quality products to enhance your cycling
            experience. We are passionate about cycling and dedicated to
            providing top-notch accessories to cyclists of all levels. Whether
            you're a seasoned pro or just starting, we have the gear you need to
            elevate your ride.
          </p>

          <button>Read More</button>
        </div>
      </div>
    </div>
  );
};

export default ProductsSection;

import React, { useEffect } from "react";
import Navbar from "./Navbar";
import styles from "./css/Home.module.css";
import HeroSection from "./HeroSection";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
// import paraImg from "../img/element.png";

const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={styles.parent}>
      <div className={styles.backColor}>
        <div className={styles.navbarContainer}>
          <Navbar />
        </div>
        <div className={styles.heroSectionContainer}>
          <HeroSection />
        </div>
      </div>
      <div className={styles.aboutUsContainer}>
        <AboutUs />
        <hr />
      </div>
      <div className={styles.contactUsContainer}>
        <ContactUs />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;

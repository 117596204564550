import React from "react";
import "./contact.scss";
import { FaInstagram, FaPhone } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

const Contact = () => {
  return (
    <div className="contact-container">
      <h2>Contact Me</h2>
      <div className="contact-info">
        <div className="info-item">
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=ahmadshahzad.9038@gmail.com">
            ahmadshahzad.9038@gmail.com
          </a>
        </div>
        <div className="info-item">
          <a href="tel:03097845649">03097845649</a>
        </div>
      </div>
    </div>
  );
};

export default Contact;

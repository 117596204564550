import React from "react";
import "./footer.scss";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";

const Footer = () => {
  return (
    <>
      <div className="footerParent">
        <hr style={{ width: "40%", margin: "50px 0" }} />
        <footer>
          <div className="footerContainer">
            <div className="footerColumn">
              <h4 className="footerHeading">About Us</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                non mauris luctus, bibendum eros eget, eleifend quam.
              </p>
            </div>

            <div className="footerColumn">
              <h4 className="footerHeading">Contact Us</h4>
              <p>123 Main St. New York, NY 10001</p>
              <p>Email: ahmadshahzad.9038@gmail.com</p>
              <p>Phone: 03097845649</p>
            </div>

            <div className="footerColumn">
              <h4 className="footerHeading">Follow Us On</h4>
              <ul className="socialMediaIcons">
                <li>
                  <TiSocialFacebook />
                </li>
                <li>
                  <TiSocialInstagram />
                </li>
                <li>
                  <TiSocialTwitter />
                </li>
                <li>
                  <TiSocialYoutube />
                </li>
              </ul>
            </div>

            <div className="footerColumn">
              <h4 className="footerHeading">Subscribe</h4>
              <p>Sign up to receive updates and special offers:</p>
              <form action="#" method="POST">
                <input
                  type="email"
                  name="email"
                  className="emailInput"
                  placeholder="Enter your Message here"
                />
                <button type="submit" className="submitButton">
                  Send
                </button>
              </form>
            </div>
          </div>
          <hr className="divider" />
          <p className="footerText">&copy; 2023 Profolio by M_ahmadShahzad.</p>
        </footer>
      </div>
    </>
  );
};

export default Footer;

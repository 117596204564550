import React, { useEffect, useState } from "react";
import "../scss/photoFrontend.scss";
import { useLocation } from "react-router-dom";

//icon
import { GoTriangleDown } from "react-icons/go";
import { RxHamburgerMenu } from "react-icons/rx";
import GallerySection from "./GallerySection";
import FeaturesSection from "./FeaturesSection";
import AboutSection from "./AboutSection";
import TestimonialSection from "./TestimonialSection";
import ContactSection from "./ContactSection";
import Footer from "./Footer";

const PhotoWebsite = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div className="photo-website">
        <div className="container">
          <nav className="navbar">
            <div className="logo">PHOCLICK</div>

            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <button href="#">Contact Us</button>
              </li>
            </ul>
            <RxHamburgerMenu
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              className="hamburger-menu"
            />
          </nav>
          {isMobileMenuOpen && (
            <ul className="mobile-menu">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <button href="#">Contact Us</button>
              </li>
            </ul>
          )}
          <div className="left-section">
            <div className="data">
              <h1>Discover the world through our lens.</h1>
              <p>
                Immerse Yourself in a Visual Odyssey, Where Each Image Weaves a
                Captivating Tale.
              </p>
              <span>
                <button>Get in Touch</button>
              </span>
              <div>
                Book your appointment{" "}
                <GoTriangleDown style={{ marginTop: "10px" }} />
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="image-background">{/* Your image goes here */}</div>
          </div>
        </div>
        <div className="upperCircle"></div>
        <div className="lowerCircle"></div>
      </div>

      <GallerySection />
      <FeaturesSection />
      <AboutSection />
      <TestimonialSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default PhotoWebsite;

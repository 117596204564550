import React from "react";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { RiTwitterFill, RiLinkedinFill, RiFacebookFill } from "react-icons/ri";
import "./contactUs.scss"; // Import your SCSS stylesheet

const ContactPage = () => {
  return (
    <div className="contactPage">
      <hr style={{ width: "40%", margin: "50px 0" }} />
      <h2>
        Contact Us<span>.</span>
      </h2>

      <div className="contactContainer">
        <div className="contactForm">
          <div className="formRow">
            <div className="formColumn">
              <label htmlFor="firstName">First Name:</label>
              <input type="text" id="firstName" />
            </div>
            <div className="formColumn">
              <label htmlFor="lastName">Last Name:</label>
              <input type="text" id="lastName" />
            </div>
          </div>
          <div className="formRow">
            <div className="formColumn">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" />
            </div>
            <div className="formColumn">
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" />
            </div>
          </div>
          <div className="messageRow">
            <label htmlFor="message">Message:</label>
            <textarea id="message" rows="4"></textarea>
          </div>
          <button className="submitButton">Submit</button>
        </div>
        <div className="mapContainer">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3035.0424461682465!2d-73.98666138457888!3d40.74844014252648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2594ce1b4d91f%3A0x2b2c063ebf44a1a4!2sEmpire%20State%20Building!5e0!3m2!1sen!2sus!4v1620898595994!5m2!1sen!2sus"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

import React from "react";
import "./aboutSection.scss";
import img1 from "../scss/image/1.jpg";

const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="container">
        {" "}
        <div className="about-content">
          <h2>About Us</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eget
            justo euismod, vehicula sapien sed, dignissim velit. Nullam
            malesuada sapien ac lacus lacinia, a posuere justo hendrerit.
            Vivamus fringilla justo vitae arcu tristique, eget varius eros
            pulvinar. Proin vel metus in ipsum varius aliquam. Phasellus ut
            hendrerit nulla. Vestibulum ante ipsum primis in faucibus orci
            luctus et ultrices posuere cubilia curae; Aliquam erat volutpat.
          </p>
          <p>
            Sed non tincidunt arcu. Maecenas feugiat, libero a eleifend
            tristique, nunc tortor tincidunt lectus, sit amet iaculis dui odio
            ut augue. Vivamus quis arcu quis nulla lacinia venenatis. Integer
            dapibus purus a nibh commodo, in blandit tortor condimentum.
            Phasellus vel justo eu tellus varius mattis vel eget odio. Proin
            rhoncus id mi ac hendrerit.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

import React from "react";
import "./projects.scss";
import cycleImg from "../projectImages/cycle.png";
import dynamicImg from "../projectImages/dynamic.png";
import photographImg from "../projectImages/photo.png";
import shoe from "../projectImages/shoe.png";
import chatApp from "../projectImages/socialApp.PNG";
import realEstate from "../projectImages/realEstate.PNG";
import mediaApp from "../projectImages/mediaApp.PNG";
import { Link } from "react-router-dom";

const projectData = [
  {
    id: 7,
    title: "MERN Stack social app",
    description:
      "Website with secure signup, login, verification, authentication and forgot password functionality, coupled with dynamic linking capabilities.",
    image: mediaApp,
    link: "https://kind-teal-cygnet-garb.cyclic.app/",
  },
  {
    id: 6,
    title: "Moden Landing page",
    description: "Real Estate Modern Landing page frontend using React js",
    image: realEstate,
    link: "https://d7a3d7e3.realestate-a55.pages.dev/",
  },
  {
    id: 5,
    title: "Messages chat app",
    description: "Full stack Message Chat Web App using MERN stack",
    image: chatApp,
    link: "https://tan-cheerful-frog.cyclic.app/",
  },
  {
    id: 2,
    title: "e-commerce website",
    description: "Complete shopping mart website using MERN stack",
    image: dynamicImg,
    link: "https://ill-plum-bunny-boot.cyclic.app/",
  },
  {
    id: 1,
    title: "landing page frontend",
    description:
      "Frontend for a Cycle Shop landing page, seamless user interaction and responsiveness",
    image: cycleImg,
    link: "/cycleStore-landingPage-portfolio",
  },

  {
    id: 3,
    title: "landing page frontend",
    description:
      "React Frontend for a Photography Shop landing page, seamless user interaction and responsiveness",
    image: photographImg,
    link: "/photography-website-portfolio",
  },
  {
    id: 4,
    title: "landing page frontend",
    description: "Landing page for a Shoe Shop with responsiveness",
    image: shoe,
    link: "/shoe-website-portfolio",
  },
];

const Projects = () => {
  return (
    <div className="projects-container">
      <hr />
      <h2>Projects</h2>
      <div className="projects">
        {projectData.map((project) => (
          <div key={project.id} className="project-box">
            <img src={project.image} alt={project.title} />
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <Link to={project.link}>
              <button>View Project</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;

import React from "react";
import "./aboutUsSection.scss";
import { LiaBicycleSolid } from "react-icons/lia";
import { FiTarget } from "react-icons/fi";
import { BsBoxSeam } from "react-icons/bs";
import { HiUsers } from "react-icons/hi";

const AboutUsSection = () => {
  return (
    <section className="about-us">
      <hr style={{ width: "40%", margin: "50px 0" }} />
      <h2>
        Our Amazing Features<span>.</span>
      </h2>
      <div className="about-us-boxes">
        <div className="about-us-box">
          <LiaBicycleSolid className="aboutUsIcons" />
          <h3>Our Story</h3>
          <p>
            Our Company were founded in 2012 with a passion for cycling. From
            than we providing best services
          </p>
        </div>
        <div className="about-us-box">
          <FiTarget className="aboutUsIcons" />
          <h3>Our Mission</h3>
          <p>
            To provide top-notch cycling products and exceptional customer
            service.
          </p>
        </div>
        <div className="about-us-box">
          <BsBoxSeam className="aboutUsIcons" />
          <h3>Our Values</h3>
          <p>
            We always prioritizing honesty and transparency in our interactions
            with clients, partners, and team members.
          </p>
        </div>
        <div className="about-us-box">
          <HiUsers className="aboutUsIcons" />
          <h3>Our Services</h3>
          <p>
            Wide array of cycling accessories, helmets, gloves, lights, and
            more, to keep you safe and comfortable on the road.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;

import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CycleFrontend from "./cycleWebsite/CycleFrontend";
import PhotoWebsite from "./photoWebsite/PhotoWebsite";
import Portfolio from "./portfolio/Portfolio";
import Home from "./shoe/Home";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route
            path="/cycleStore-landingPage-portfolio"
            element={<CycleFrontend />}
          />
          <Route
            path="/photography-website-portfolio"
            element={<PhotoWebsite />}
          />
          <Route path="/shoe-website-portfolio" element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

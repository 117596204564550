import React from "react";
import "./gallerySection.scss"; // Make sure you have the corresponding SCSS file
import img2 from "../scss/image/2.jpg";
import img6 from "../scss/image/6.jpg";
import img7 from "../scss/image/7.jpg";
import img8 from "../scss/image/8.jpg";
import img9 from "../scss/image/9.jpg";
import img10 from "../scss/image/10.jpg";

const GallerySection = () => {
  return (
    <div className="gallery-container">
      <div className="heading">
        <div className="border"></div>
        <p>Client Album</p>
        <h2>Our Impressive Portfolio of Client Projects</h2>
      </div>

      <div className="insideContainer">
        <section className="gallery-row">
          <div className="gallery-image">
            <img src={img9} alt="Image 1" />
          </div>
          <div className="gallery-image">
            <img src={img2} alt="Image 2" />
          </div>
          <div className="gallery-image">
            <img src={img8} alt="Image 3" />
          </div>
        </section>
        <section className="gallery-row">
          <div className="gallery-image">
            <img src={img6} alt="Image 4" />
          </div>
        </section>
        <section className="gallery-row">
          <div className="gallery-image">
            <img src={img10} alt="Image 5" />
          </div>
          <div className="gallery-image">
            <img src={img7} alt="Image 1" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default GallerySection;

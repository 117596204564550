import React, { useRef } from "react";
import "../scss/portfolio.scss";
import { BiLogoMongodb, BiLogoReact } from "react-icons/bi";
import { SiExpress } from "react-icons/si";
import { IoLogoNodejs } from "react-icons/io";
import { Typewriter } from "react-simple-typewriter";
import img from "./images/1.png";
import Projects from "./Projects";
import Contact from "./Contact";

const Portfolio = () => {
  const projectsRef = useRef(null); // Create a ref for the projects section

  const scrollToProjects = () => {
    // Scroll to the projects section when the button is clicked
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="portfolio">
      <div className="navbar">
        <div className="logo">Portfolio.</div>
        <button className="projects-button" onClick={scrollToProjects}>
          Projects
        </button>
      </div>
      <div className="content">
        <div className="left-section">
          <h2>
            <span>Hello</span> i'm
          </h2>
          <h1>Ahmad Shahzad</h1>
          <h3>
            <Typewriter
              words={[
                "Website developer",
                "MERN stack developer",
                "React developer",
                "Front-end developer",
                "Back-end Nodejs developer",
              ]}
              loop={Infinity}
              cursor
              cursorStyle="|"
              typeSpeed={30}
              deleteSpeed={30}
              delaySpeed={1000}
            />
          </h3>
          <p>
            MERN stack developer with expertise in MongoDB, Express.js,
            React.js, and Node.js. Experienced in designing responsive user
            interfaces and implementing seamless backend functionality.
          </p>
          <div className="icons">
            <BiLogoMongodb className="mongodb" />
            <BiLogoReact className="react" />
            <SiExpress className="express" />
            <IoLogoNodejs className="node" />
          </div>
        </div>
        <div className="right-section">
          <img src={img} alt="Portfolio Image" className="portfolio-image" />
          <div className="box"></div>
        </div>
      </div>

      {/* Add a ref to the projects section */}
      <div className="ref" ref={projectsRef}>
        <Projects />
      </div>

      <Contact />
    </div>
  );
};

export default Portfolio;

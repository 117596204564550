import React from "react";
import { FaBriefcase, FaHistory, FaUsers, FaCog } from "react-icons/fa";
import "./featuresSection.scss";

const FeaturesSection = () => {
  return (
    <div className="features-section">
      <div className="heading">
        <div className="border"></div>
        <h2>Our Amazing Features</h2>
      </div>
      <div className="container">
        <div className="feature-box">
          <FaBriefcase className="feature-icon" />
          <h2>Our Services</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className="outline"></div>
        </div>
        <div className="feature-box">
          <FaHistory className="feature-icon" />
          <h2>Our Story</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className="outline"></div>
        </div>
        <div className="feature-box">
          <FaUsers className="feature-icon" />
          <h2>Our Team</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className="outline"></div>
        </div>
        <div className="feature-box">
          <FaCog className="feature-icon" />
          <h2>Our Process</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className="outline"></div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;

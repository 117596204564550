import React, { useEffect, useState } from "react";
import "../scss/cycleFrontend.scss";
import logo from "../scss/image/logo.png";
import { BiLogoFacebook } from "react-icons/bi";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { RxHamburgerMenu } from "react-icons/rx";
import styles from "./style.module.css";
import { useLocation } from "react-router-dom";

//components
import AboutUsSection from "./AboutUsSection";
import ProductsSection from "./ProductsSection";
import TestimonialSection from "./TestimonialSection";
import ContactPage from "./ContactUs";
import Footer from "./Footer";

const CycleFrontend = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="header">
        <nav className="navbar">
          <div className="navbar-left">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <div className="navbar-right">
            <ul className="nav-links">
              <li>Home</li>
              <li>Products</li>
              <li>Features</li>
            </ul>
            <button
              className="contact-btn"
              onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
            >
              Contact Us
            </button>
          </div>
          <RxHamburgerMenu
            className="hamburger-menu"
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          />
        </nav>
        {isMobileMenuOpen && (
          <div className="mobile-menu">
            <ul>
              <li>Home</li>
              <li>Products</li>
              <li>Features</li>
            </ul>
            <button className="contact-btn">Contact Us</button>
          </div>
        )}
        <section className="main-section">
          <div className="info-box-container">
            <div className="info-box">
              <h1>
                A long way to travel<span>.</span>
              </h1>
              <div className="paragraph">
                We are providing top-notch cycling products and exceptional
                customer service.
              </div>
              <button className="join-btn">Join Us</button>
            </div>
            <div className="circle1"></div>
            <div className="circle2"></div>
            <p className="link-short-line">
              <div>
                <a href="">Twitter</a> . <a href="">Facebook</a> .
                <a href="">Instagram</a>
              </div>
            </p>
          </div>
        </section>
        <div className="icon">
          <div className="icon-item">
            <AiFillInstagram />
          </div>
          <div className="icon-item">
            <BiLogoFacebook />
          </div>
          <div className="icon-item">
            <BsTwitter />
          </div>
        </div>
      </div>

      <AboutUsSection />
      <ProductsSection />
      <TestimonialSection />
      <ContactPage />
      <Footer />
    </>
  );
};

export default CycleFrontend;
